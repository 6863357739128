<template>
  <v-container fluid>
    <v-data-iterator
      :items="events"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading.table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :page="page"
      :search="search"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 15, 20, 30, 50, 100]
      }"
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="event in props.items"
            :key="event._id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <eventCard
              :event="event"
              unApproved
              @reject="reject"
              @approve="approve"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import eventCard from "@/components/Calendar/eventCard";
import { calendarService } from "@/services/calendar";

export default {
  data: function() {
    return {
      events: [],
      loading: { table: false, reject: false, approve: false },
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 15
      },
      page: 1,
      search: "",
      sortBy: undefined,
      sortDesc: undefined
    };
  },
  components: {
    eventCard
  },
  methods: {
    reject(event) {
      this.loading.reject = true;
      calendarService
        .rejectEvent(event._id)
        .then(() => {
          this.getItems();
        })
        .finally(() => (this.loading.reject = false));
    },
    approve(event) {
      this.loading.approve = true;
      calendarService
        .approvedEvent(event._id)
        .then(() => {
          this.getItems();
        })
        .finally(() => (this.loading.approve = false));
    },
    getItems() {
      this.loading.table = true;
      calendarService
        .getUnApproved(this.options.page, this.options.itemsPerPage)
        .then(res => {
          this.events = res.data.items;
          this.total = res.data.total;
        })
        .finally(() => (this.loading.table = false));
    }
  },
  created() {
    this.getItems();
  }
};
</script>

<style lang="scss" scoped></style>
